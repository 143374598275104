import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, MenuItem, Select, Typography, type SelectChangeEvent } from '@mui/material'
import { ROUTES_PATH } from 'config'
import { useAuth } from 'context'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface ServiceAddressType {
    legacyAcctNumber: string
    premiseId: string
    serviceAddress: string
    accountId: string

}

export const ServiceAddress: React.FC = () => {
    const { account, handleCurrentAccount } = useAuth()
    const [serviceAddresses, setServiceAddresses] = useState<ServiceAddressType[]>([])
    const [selectedAddress, setSelectedAddress] = useState<ServiceAddressType>({
        legacyAcctNumber: '',
        premiseId: '',
        serviceAddress: '',
        accountId: ''
    })
    const isMobile = useMobileBreakpoints()
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if ((account?.myAccount?.serviceAddresses) != null) {
            const uniqueServiceAddresses = account.myAccount?.serviceAddresses.filter(
                (value, index, self) =>
                    self.findIndex((v) => v.accountId === value.accountId) === index
            )
            setServiceAddresses(uniqueServiceAddresses)
            setSelectedAddress(uniqueServiceAddresses[0])
        }
    }, [account])

    const handleChange = async (e: SelectChangeEvent): Promise<void> => {
        const tempAddress = serviceAddresses.find((address) => address.accountId === (e.target.value))
            ; (tempAddress != null) && setSelectedAddress(tempAddress)
        handleCurrentAccount({ accountId: tempAddress?.accountId ?? '', premiseId: tempAddress?.premiseId ?? '' })
        navigate(ROUTES_PATH.DASHBOARD)
    }

    return (
        <> {
            isMobile
                ? <Box display="flex" flexDirection="column">
                    <Typography color="#5D6365" fontSize={12}>Service Addresss</Typography>
                    <Select variant="standard" value={selectedAddress?.accountId !== '' ? selectedAddress?.accountId : 'No active accounts'} onOpen={() => { setIsOpen(true) }} onClose={() => { setIsOpen(false) }} onChange={handleChange} disableUnderline={true} sx={{ fontSize: '16px' }} IconComponent={() => isOpen ? <ExpandLessIcon sx={{ color: '#7270F7' }} /> : <ExpandMoreIcon sx={{ color: '#7270F7' }} />}>
                        {serviceAddresses.length === 0 && (
                            <MenuItem value="No active accounts" disabled>No active accounts</MenuItem>
                        )}
                        {serviceAddresses.length !== 0 && serviceAddresses.map((address: ServiceAddressType, index) => {
                            return (
                                <MenuItem key={index} value={address.accountId}>
                                    <Typography fontSize={14} textTransform="uppercase" noWrap>
                                    {`${address?.serviceAddress} | ${address.accountId}`}
                                    </Typography>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Box >
                : <Select
                    onOpen={() => { setIsOpen(true) }} onClose={() => { setIsOpen(false) }}
                    fullWidth
                    sx={{
                        fontSize: '14px',
                        color: '#ffffff',
                        border: '1px solid #4E53C4',
                        borderRadius: '0px 0px 2px 2px'
                    }}
                    value={selectedAddress?.accountId !== '' ? selectedAddress?.accountId : 'No active accounts'}
                    onChange={handleChange}
                    IconComponent={() => isOpen ? <ExpandLessIcon sx={{ color: '#7270F7' }} /> : <ExpandMoreIcon sx={{ color: '#7270F7' }} />}
                >
                    {serviceAddresses.length === 0 && (
                        <MenuItem value="No active accounts" disabled>No active accounts</MenuItem>
                    )}
                    {serviceAddresses.length !== 0 && serviceAddresses.map((address: ServiceAddressType, index) => {
                        return (
                            <MenuItem key={index} value={address.accountId}>
                                <Typography fontSize={14} textTransform="uppercase" noWrap>
                                    {`${address?.serviceAddress} | ${address.accountId}`}
                                </Typography>
                            </MenuItem>
                        )
                    })}
                </Select>
        }
        </>
    )
}
