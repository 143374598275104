import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Link, OutlinedInput, Typography } from '@mui/material'
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth'
import { AlertDialog, ErrorText, MButton } from 'components'
import { MESSAGES, ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { BackToLogin } from 'containers/Auth/SignUp/component'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import * as yup from 'yup'

const schema = yup
  .object({
    username: yup.string().email().required('Email is required'),
    code: yup.string().required('Code is required')
  })
  .required()

interface ISignUpConfirmForm {
  username: string
  code: string
}

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useLocation()
  const [formData, setFormData] = useState<any>({})
  const email = state ?? ''
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isResendCodeDialogOpen, setIsResendCodeDialogOpen] = useState<boolean>(false)
  const [isResendCodeErrorDialogOpen, setIsResendCodeErrorDialogOpen] = useState<boolean>(false)
  const resendCodeMessage = 'Confimation code has been resent. Please allow a few minutes for it to arrive. Please check your spam folder as well.'
  const [resendCodeError, setResendCodeError] = useState<string>(MESSAGES.WRONG_ERROR)

  const handleResendCodeDialogOpen = (): void => {
    setIsResendCodeDialogOpen(!isResendCodeDialogOpen)
  }

  const handleResendCodeErrorDialogOpen = (): void => {
    setIsResendCodeErrorDialogOpen(!isResendCodeErrorDialogOpen)
  }


  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<ISignUpConfirmForm>({
    mode: 'all',
    defaultValues: {
      username: email,
      code: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: ISignUpConfirmForm): Promise<void> => {
    const { username, code } = data
    setIsLoading(true)
    setFormData(data)
    try {
      const { isSignUpComplete } = await confirmSignUp({ username, confirmationCode: code })
      if (isSignUpComplete) {
        await APIs.confirmRegister({
          email: username
        })
        navigate(ROUTES_PATH.SIGNUP_SUCCESS)
      } else {
        console.error('SignUpConfirm.Form.Register: Sign up not complete')
      }
    } catch (e: any) {
      console.error('SignUpConfirm.Form.Register', e)
      enqueueSnackbar(e?.message || MESSAGES.WRONG_ERROR, {
        variant: 'error'
      })
    }
    setIsLoading(false)
  }

  const handleOnChange = (e: any): void => {
    const username = e?.target?.defaultValue
    setFormData({ username })
  }

  const resendCode = async (): Promise<void> => {
    try {
      const username = email !== '' ? email : formData.username
      const response = await resendSignUpCode({ username })
      if (response && 'CodeDeliveryDetails' in response) {
        handleResendCodeDialogOpen()
      } else {
        console.error(response)
        handleResendCodeErrorDialogOpen()
      }
    } catch (e: any) {
      console.error('SignUpConfirm.Form.Register', e)
      setResendCodeError(e?.message)
      handleResendCodeErrorDialogOpen()
    }
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Typography color="#010B0E" fontSize={24} fontWeight={600} mb={3}>
        Confirmation Code
      </Typography>
      <Box my={2}>
        <FormLabel htmlFor="username">Email address</FormLabel>
        <Controller
          name="username"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              name={name}
              placeholder="Enter your email address"
              onKeyUpCapture={handleOnChange}
              onChange={onChange}
              value={value}
              type="text"
              fullWidth
            />
          )}
        />
        {errors.username && <ErrorText>{errors.username.message}</ErrorText>}
      </Box>

      <Box my={2}>
        <FormLabel htmlFor="code">Code</FormLabel>
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              name={name}
              placeholder="000000"
              onChange={onChange}
              value={value}
              type="text"
              fullWidth
            />
          )}
        />
        {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
      </Box>
      <Box
        mt={3}
        textAlign="center"
        bgcolor='#EEF3F6'
        p={1}
      >
        <Typography
          display="inline"
          sx={{
            fontWeight: 600,
            fontSize: '12px'
          }}
        >
          {' '}
          Didn&apos;t get a code?{' '}
        </Typography>
        <Typography
          display="inline"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            textDecoration: 'none'
          }}
        >
          Request a new code by
          <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={resendCode}>
            {' '}
            clicking here
          </Link>
          .
        </Typography>
      </Box>
      <Box my={3} textAlign="center">
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          loading={isLoading}
          disabled={!isValid || isLoading}
          sx={{ width: '200px' }}
        >
          Confirm
        </MButton>
      </Box>
      <BackToLogin />
      <AlertDialog open={isResendCodeDialogOpen} handleClick={handleResendCodeDialogOpen} type="success" message={resendCodeMessage} />
      <AlertDialog open={isResendCodeErrorDialogOpen} handleClick={handleResendCodeErrorDialogOpen} type="error" message={resendCodeError} />
    </form >

  )
}
