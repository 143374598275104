import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput
} from '@mui/material'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import { AlertDialog, ErrorText, MButton, Text } from 'components'
import { MESSAGES, ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { BackToLogin } from 'containers/Auth/SignUp/component'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import PasswordNotificationModal from './PasswordNotificationModal'

const schema = yup
  .object({
    code: yup
      .string()
      .required('Code is required')
      .matches(/^\d{6}$/, 'Code must be exactly 6 digits'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/,
        'Password must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character'
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })
  .required()

interface IResetPwdForm {
  code: string
  password: string
  passwordConfirm: string
}

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IResetPwdForm>({
    mode: 'all',
    defaultValues: {
      code: '',
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(schema)
  })
  const location = useLocation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [authError, setAuthError] = useState<string>('')
  const email = location.state?.email ?? ''
  const [isResendCodeDialogOpen, setIsResendCodeDialogOpen] = useState<boolean>(false)
  const [isResendCodeErrorDialogOpen, setIsResendCodeErrorDialogOpen] = useState<boolean>(false)
  const resendCodeMessage = 'Password reset code has been resent. Please allow a few minutes for it to arrive. Please check your spam folder as well.'
  const [resendCodeError, setResendCodeError] = useState<string>(MESSAGES.WRONG_ERROR)

  const handleResendCodeDialogOpen = (): void => {
    setIsResendCodeDialogOpen(!isResendCodeDialogOpen)
  }

  const handleResendCodeErrorDialogOpen = (): void => {
    setIsResendCodeErrorDialogOpen(!isResendCodeErrorDialogOpen)
  }

  useEffect(() => {
    if (email === '') {
      navigate(ROUTES_PATH.FORGOT_PASSWORD)
    }
  }, [])


  const onSubmit = async (data: IResetPwdForm): Promise<void> => {
    const { code, password } = data
    try {
      await confirmResetPassword({ username: email, confirmationCode: code, newPassword: password })
      setShowModal(true)
    } catch (error) {
      if (error instanceof Error) {
        setAuthError(error.message)
      }
    }
  }

  const resendCode = async (): Promise<void> => {
    try {
      await resetPassword({ username: email })
      handleResendCodeDialogOpen()
    } catch (e: any) {
      setResendCodeError(e?.message)
      handleResendCodeErrorDialogOpen()
    }
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Text fontSize="16px" color="#5D6365" margin="0 0 20px">
        Verification code has been sent to your email address.
      </Text>
      <Box
        my={2}
        sx={{
          backgroundColor: '#EEF3F6',
          padding: 2
        }}
      >
        <Text color="black" fontSize="12px" align="center">
          <b>Didn&apos;t get a code?</b> Request a new code by{' '}
          <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={resendCode}>
            clicking here.
          </Link>
        </Text>
      </Box>
      <Box my={2}>
        <FormLabel>Code</FormLabel>
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              name={name}
              placeholder="Code"
              onChange={onChange}
              value={value}
              type="text"
              fullWidth
            />
          )}
        />
        {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
      </Box>
      <Box my={2}>
        <FormLabel>Password</FormLabel>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              id="password"
              placeholder="Enter a new password"
              type={showPassword ? 'text' : 'password'}
              onChange={onChange}
              value={value}
              name={name}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { setShowPassword(!showPassword) }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
      </Box>
      <Box my={2}>
        <FormLabel>Confirm password</FormLabel>
        <Controller
          name="passwordConfirm"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              id="passwordConfirm"
              placeholder="Confirm your password"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={onChange}
              value={value}
              name={name}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.passwordConfirm && (
          <ErrorText>{errors.passwordConfirm.message}</ErrorText>
        )}
      </Box>

      {authError && <ErrorText>{authError}</ErrorText>}

      <Box my={2} textAlign="center">
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          disabled={!isValid}
          sx={{ width: '160px' }}
        >
          SUBMIT
        </MButton>
      </Box>

      <Link
        onClick={() => {
          navigate(ROUTES_PATH.FORGOT_PASSWORD)
        }}
        underline="none"
      ></Link>
      <BackToLogin />
      <PasswordNotificationModal open={showModal} />
      <AlertDialog open={isResendCodeDialogOpen} handleClick={handleResendCodeDialogOpen} type="success" message={resendCodeMessage} />
      <AlertDialog open={isResendCodeErrorDialogOpen} handleClick={handleResendCodeErrorDialogOpen} type="error" message={resendCodeError} />
    </form>
  )
}
