import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccountIcon from '@mui/icons-material/AccountCircle'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import FacebookIcon from '@mui/icons-material/Facebook'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import MailIcon from '@mui/icons-material/Mail'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import WarningIcon from '@mui/icons-material/Warning'
// import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import WindowIcon from '@mui/icons-material/Window'
import { ROUTES_PATH } from './routes'

export const EFFICIENCY_LINK = 'https://www.moval.org/mvu/efficiency-progs.html'
export const OUTAGES_LINK = 'https://mvu.encous.com'
export const RATES_LINK = 'https://www.moval.org/mvu/rates-tariffs.html'
export const PAPERLESS_LINK = 'https://invoicecloud.net/payer-terms-conditions'

export const EFFICIENCY_ARTICLES = [
  { title: 'AC or HP Tune-up Rebate', link: 'https://www.moval.org/mvu/pdfs/AC-HPTuneUpRebateApp.pdf' },
  { title: 'Business Rebate Application', link: 'https://www.moval.org/mvu/pdfs/BusinessRebateApplication.pdf' },
  { title: 'Commercial Energy Audits and Direct Install', link: 'https://www.moval.org/mvu/pdfs/CommercialEnergyAuditApp.pdf' },
  { title: 'Energy Efficiency Programs', link: 'https://www.moval.org/mvu/pdfs/MVUEnergyEfficiencyPrograms.pdf' }
]
export const PAY_TOOLS = [
  { title: 'Autopay', Icon: CurrencyExchangeOutlinedIcon, page: 3 },
  { title: 'Paperless Billing', Icon: InsertPageBreakOutlinedIcon, page: 2 },
  { title: 'Pay Bill', Icon: PaymentsOutlinedIcon, page: 1 },
  {
    title: 'View Bill',
    Icon: ReceiptOutlinedIcon,
    navigate: ROUTES_PATH.BILLING
  },
  { title: 'Wallet', Icon: PaymentOutlinedIcon, page: 5 },
  { title: 'Scheduled Payments', Icon: LocalAtmIcon, page: 9 }
]

export const LOGIN_ITEMS = [
  {
    title: 'Problems Signing In?',
    icon: LockOpenIcon,
    link: ROUTES_PATH.FORGOT_PASSWORD
  },
  {
    title: 'One-Time Payment',
    icon: PaymentsOutlinedIcon,
    link: ROUTES_PATH.PAY
  },
  {
    title: 'Services',
    icon: FileCopyIcon,
    link: ROUTES_PATH.SERVICES
  },
  {
    title: 'Outages',
    icon: WarningIcon,
    link: ROUTES_PATH.OUTAGES
  },
  {
    title: 'Efficiency',
    icon: DoneAllIcon,
    link: EFFICIENCY_LINK
  },
  {
    title: 'Contact Us',
    icon: ChatBubbleIcon,
    link: ROUTES_PATH.CONTACT_US
  }
]

export const SERVICE_ITEMS = [
  {
    title: 'Start Service',
    content:
      'To start new MVU electrical service, please call customer service at 1-844-341-6469.<br><br> Please have the following information available:',
    list: [
      'Service address',
      'Social Security Number & driver\'s license number',
      'Federal Tax ID# (if business)',
      'Primary phone number',
      'Date to start service (additional fees apply for same day service, weekends, or holidays)',
      'Mailing address (if different from service address)'
    ],

    subcontent:
      '*Please note. There will be a $15.00 activation fee and a $5.00 per person identity verification charge applied to your account'
  },
  {
    title: 'Stop service',
    content: 'To discontinue MVU residential or commercial electric service please call customer service at 1-844-341-6469. Please have the following information available:',
    list: ['Service address', 'Personal identification information']
  },
  {
    title: 'Move service',
    content: 'If you are moving within the MVU service territory please call customer service at 1-844-341-6469 and we\'d be happy to help you transfer your account to your new address.'
  }
]

export const SERVICES = [
  {
    title: 'Start Service',
    content:
      'To start new MVU electrical service, please call customer service at 1-844-341-6469.<br><br> Please have the following information available:',
    list: [
      'Service address',
      'Social Security Number & driver\'s license number',
      'Federal Tax ID# (if business)',
      'Primary phone number',
      'Date to start service (additional fees apply for same day service, weekends, or holidays)',
      'Mailing address (if different from service address)'
    ]
  },
  {
    title: 'Safety',
    content: 'For life-threathening emergencies, please dial 911. If you encounter safety issues such as power outages, downed power lines, or hazardous conditions involving electrical equipment please contact our 24/7 customer service line at 844-341-6469'
  }
]


export const SIDEBAR_ITEMS = [
  { title: 'Dashboard', icon: WindowIcon, href: ROUTES_PATH.DASHBOARD },
  { title: 'Usage', icon: AccessTimeIcon, href: ROUTES_PATH.USAGE },
  { title: 'Billing', icon: ReceiptLongIcon, href: ROUTES_PATH.BILLING },
  { title: 'Outages', icon: WarningIcon, href: ROUTES_PATH.AUTHORIZED_OUTAGES },
  {
    title: 'Services',
    icon: FileCopyIcon,
    href: ROUTES_PATH.AUTHORIZED_SERVICES
  },
  {
    title: 'Efficiency',
    icon: DoneAllIcon,
    href: EFFICIENCY_LINK
  },
  { title: 'Contact Us', icon: ChatBubbleIcon, href: ROUTES_PATH.CONTACT },
  { title: 'My Account', icon: AccountIcon, href: ROUTES_PATH.ACCOUNT },
  {
    title: 'Account Preferences',
    icon: SettingsOutlinedIcon,
    href: ROUTES_PATH.SETTINGS
  }
]

export const AUTH_TOPBAR_ITEMS = [
  { title: 'Services', href: ROUTES_PATH.SERVICES },
  { title: 'Efficiency', href: ROUTES_PATH.EFFICIENCY },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT_US },
  { title: 'Outages', href: ROUTES_PATH.OUTAGES }
]

export const TOPBAR_ITEMS = [
  { title: 'Usage', href: ROUTES_PATH.USAGE },
  { title: 'Billing', href: ROUTES_PATH.BILLING },
  { title: 'Services', href: ROUTES_PATH.AUTHORIZED_SERVICES },
  { title: 'Efficiency', href: ROUTES_PATH.EFFICIENCY },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT },
  { title: 'Account', href: ROUTES_PATH.ACCOUNT },
  { title: 'Account Preferences', href: ROUTES_PATH.SETTINGS },
  { title: 'Dashboard', href: ROUTES_PATH.PORTAL_DASHBOARD },
  { title: 'Outages', href: ROUTES_PATH.OUTAGES }
]

export const MESSAGES = {
  VERIFICATION_CODE_CHECK:
    'A verification code has been sent to your email. Please check your inbox and enter the code below.',
  VERIFICATION_RESENT:
    'A new verification code has been resent. Please allow a few minutes for its delivery. Kindly check your spam folder as well.',
  ACCOUNT_VERIFIED:
    'Your account has been successfully verified. Please proceed to create a password for registration.',
  REQUIRED_NAME_ERROR: 'Please provide your name in the required field.',
  REQUIRED_EMAIL_ERROR:
    'Please enter a valid email address in the required field.',
  REQUIRED_TELEPHONE_ERROR:
    'Please provide a valid telephone number in the required field.',
  INVALID_TELEPHONE_ERROR:
    'Please provide a valid telephone number.',
  SELECT_ONE_ERROR: 'Please select a topic before proceeding.',
  REQUIRED_SUBJECT_ERROR: 'Please enter a subject in the required field.',
  CONTACT_CUSTOMER_ERROR:
    'If this issue persists, please get in touch with our customer service team.',
  OUTAGE_RETRIEVE_ERROR: 'An error occurred while retrieving outage data: ',
  SOMETHING_WRONG_ERROR: 'Oops, something went wrong: ',
  PREFERENCES_UPDATED_SUCCESS:
    'Your preferences have been successfully updated.',
  PREFERENCES_WARN: 'Note: Standard message and data rates may apply.',
  ENROLL_PAPERLESS_INFO:
    'You have successfully enrolled in paperless billing. Please allow up to 10 minutes for your preferences to reflect in the portal',
  UNENROLL_PAPERLESS_INFO:
    'You have successfully unenrolled in paperless billing. Please allow up to 10 minutes for your preferences to reflect in the portal',
  IC_PAPERLESS_INFO:
    'If you’ve updated your preferences, please allow up to 10 minutes for it to reflect in your account',
  PASSWORD_UPDATED_SUCCESS:
    'Your password has been changed successfully. Please log in using your new password.',
  PASSWORD_MATCH_ERROR: 'New Password and Confirm New Password do not match.',
  ZIP_CODE_ERROR: 'Please provide a valid zip code.',
  STREET_NAME_ERROR: 'Please enter a valid street name.',
  DATE_STOP_SERVICE_ERROR:
    'Please specify the date you would like to stop your service from.',
  WRONG_ERROR: 'Oops, an unexpected error occurred.',
  ENTER_DETAIL_ERROR:
    'Please provide details of your safety concern in the comments section.',
  SERVICE_REQUEST_SUCCESS:
    'Your service request has been successfully submitted.',
  REPORT_OUTAGE_SUCCESS:
    'Thank you for reporting the outage. Your report has been successfully submitted and will be addressed as soon as possible.',
  RETRIEVE_ERROR:
    'We are currently experiencing technical difficulties and unable to retrieve your information. Please try again later.',
  IC_RETRIEVE_ERROR: 'Payments are temporarily unavailable.',
  ACCOUNT_RETRIEVE_ERROR:
    'An error occurred while retrieving your account information. Please try again later.',
  FORM_SUBMIT_ERROR:
    'An error occurred while submitting the form. Please try again.',
  FORM_SUBMIT_SUCCESS:
    'Your request has been submitted successfully. You will receive a response within 2-3 business days. You will now be redirected to the Dashboard.',
  BILL_PDF_ERROR:
    'We are currently unable to retrieve the PDF version of your bill. Please try again in 24-48 hours. You can view a summary of your bill and bill history on the billing tab.',
  VIEW_PDF_ERROR:
    'It may take up to 48 hours from your bill date (you can see this in the billing history tab) to be able to view your current bill on the portal. If it\'s been more than 48 hours, please call our customer service team at 844-341-6469.',
  ACCOUNT_UPDATED_SUCCESS:
    'Your account details has been successfully updated.',
  ACCOUNT_UPDATED_ERROR:
    'An error occurred while updating your account details. Please try again later.',
  UPDATE_LIMIT_EXCEEDED:
    'Please note that you can only update your mailing address once within a 24-hour period. Kindly try again later.',
  USER_CONFIRM_WARNING:
    'Please confirm your email address by entering the confirmation code sent to your inbox.',
  OUTAGES_ERROR:
    'An error occurred while retrieving your outage information. Please try again later.',
  OUTAGES_API_ERROR:
    'An error occurred while fetching outage information. Please try again later.',
  PAYMENT_INFO:
    'If you have made a payment, it may take a few minutes for the balance to reflect the payment. Please refresh your page or log back in to view the changes.',
  BILL_ERROR:
    'We are currently unable to retrieve the PDF version of your bill. Please try again later. You can view a summary of your bill and bill history on the billing tab.',
  ADMIN_ACCESS_ERROR: 'Administrators are not permitted to do this action.',
  VERIFY_PAY_BILL_ERROR:
    'An error occurred while retrieving your account information. Please double-check your details and try again.',
  EMAIL_UPDATED_SUCCESS:
    'Your email has been changed successfully. Please log in using your new email.',
  LOGIN_ERROR:
    'There is problem logging in your account. Please try again later.'
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const USAGE_MENU_ITEMS = [
  { title: 'Calendar', label: 'Monthly View', icon: 'CalendarIcon' },
  { title: 'CalendarOne', label: 'Daily View', icon: 'Calendar1Icon' },
  { title: 'Clock', label: 'Hourly View', icon: 'ClockIcon' }
]


export const BILLING_QUESTIONS = [
  {
    title: 'When will I be receiving my bill?',
    content:
      'Bills are generated on a monthly basis, but the exact timing of bill generation varies with each billing cycle. As result, we are unable to provide a specific date for when your bill statement will be available. If you have additional questions or concerns, please call our 24/7 customer service line at 844-341-6469.'
  },
  {
    title: 'Why is my bill so high?',
    content:
      'Many customers experience increased usage during summer months due to the operation of air conditioning and cooling appliances, such as whole house fans or evaporative coolers. For any additional questions or concerns please call our 24/7 customer service line at 844-341-6469.'
  },
  {
    title: 'Do I have to pay my bill in full when I receive it?',
    content:
      'Yes, the full payment is due when you receive your bill. If you find it difficult to pay your bill in full upon receipt, please call our 24/7 customer service line at 844-341-6469 to arrange a payment plan, enabling you to distribute your payments over time.'
  },
  {
    title: 'What if I am enrolled in autopay?',
    content: 'The total amount you owe will be deducted approximately 10 days after your bill is generated. If you’re unable to pay the full amount, please call us at 844-341-6469 to discuss payment options.'
  },
  {
    title: 'How can I sign up for paperless billing?',
    content: 'You have the option to enroll either through the account preferences tab within the portal of the mobile app or by contacting our 24/7 customer service line at: 844-341-6469.'
  },
  {
    title: 'What is a payment arrangement?',
    content: 'Payment Arrangements are designed to provide greater flexibility in managing your payments by dividing any previous balance owed in monthly payments over a specified period. Eligible accounts can request a payment arrangement that will divide charges into equal installments, which are added to each month’s bill. You must pay your current charges and the installment amount by the specified due date.'
  },
  {
    title: 'What happens if I miss a payment on my payment arrangement?',
    content: 'Failure to submit payment by the bills due date may result in late fees, possible termination of your payment arrangement and service interruption. You can enroll in a new payment plan by calling our 24/7 customer service line at: 844-341-6469, be advised an additional down payment will be required to activate the new plan.'
  },
  {
    title: 'Where can I find the amount, I owe on my payment arrangement?',
    content: 'If you are on a payment arrangement, you will find the amount owed on your bill labeled payment arrangement amount. You will also find the amount and due date for the current monthly charges towards the bottom of the page of your bill. To stay on a payment arrangement, you must pay your current charges and the installment amount by the bill’s due date.'
  }
]


export const CONTACT_INFORMATION = [
  {
    icon: 'phone',
    title: 'Phone',
    description:
      '<a href="tel:8443416469">(844) 341-6469</a>.<br>(24/7 Call Center)'
  },
  {
    icon: 'location',
    title: 'Walk-in Location',
    description:
      '14331 Frederick St, Suite 2<br>Moreno Valley, CA<br>92553<br>Monday - Thursday 7:30AM - 5:30PM<br>Friday 8:00AM - 5:00PM'
  }
]

export const CONTACT_SOCIAL_ITEMS = [
  {
    icon: FacebookIcon,
    title: '/cityofmorenovalley',
    link: 'https://www.facebook.com/cityofmorenovalley'
  },
  {
    icon: null,
    title: '@CityofMoVal',
    link: 'https://twitter.com/CityofMoVal'
  },
  {
    icon: LinkedInIcon,
    title: 'City of Moreno Valley',
    link: 'https://www.linkedin.com/company/city-of-moreno-valley'
  },
  {
    icon: PermPhoneMsgIcon,
    title: '1-844-341-6469',
    link: 'tel:1-844-341-6469'
  }
]

export const FOOTER_SOCIAL_LINKS = [
  {
    icon: 'FacebookIcon',
    link: 'https://www.facebook.com/cityofmorenovalley'
  },
  {
    icon: 'TwitterIcon',
    link: 'https://twitter.com/CityofMoVal'
  },
  {
    icon: 'LinkedinIcon',
    link: 'https://www.linkedin.com/company/city-of-moreno-valley'
  }
]


export const TOPIC_ITEMS = ['Billing', 'Payment', 'Outage', 'Rebates', 'Programs', 'Report Energy Theft', 'Other']


export const EFFICIENCY_DATA = {
  title: 'Efficiency',
  subtitle: '',
  type: 'items',
  itemBackground: true,
  items: [
    {
      title: 'AC or HP Tune-up Rebate',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Business Rebate Application',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Commercial Energy Audit...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Energy Efficiency Program...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    }
  ]
}

export const ServicesFooterButtonData = [
  {
    title: 'Contact Us',
    desc: 'Need additional assistance? Contact us, we are here to help you.',
    link: ROUTES_PATH.CONTACT_US,
    linkTitle: 'Go to Contact Us',
    icon: MailIcon
  }
]

export const ServiceFormRequestType = {
  startService: 'START',
  stopService: 'STOP',
  meterReadVerification: 'MRV',
  safety: 'SAFETY'
}

export const HOLIDAYS = [
  '2025-01-01', // New Year's Day
  '2025-01-20', // Martin Luther King Jr. Day
  '2025-02-17', // Presidents Day
  '2025-03-31', // Caesar Chavez Day
  '2025-05-26', // Memorial Day
  '2025-06-19', // Juneteenth
  '2025-07-04', // 4th of July
  '2025-09-01', // Labor Day
  '2025-11-11', // Veterans Day
  '2025-11-27', // Thanksgiving
  '2025-11-28', // Day after Thanksgiving
  '2025-12-24', // Christmas Eve
  '2025-12-25' // Christmas Day
]
