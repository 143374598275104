import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box, IconButton, ListItem, ListItemButton, SwipeableDrawer, Typography, css, styled } from '@mui/material'
import Logo from 'assets/icons/MVULogo.svg'
import LogoWhite from 'assets/icons/MVULogoWhite.svg'
import { EFFICIENCY_LINK, ROUTES_PATH, SIDEBAR_ITEMS } from 'config'
import { useAuth, useSidebar } from 'context'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ServiceAddress } from './ServiceAddress'

export const SideBar: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { openSidebar, setOpenSidebar } = useSidebar()
  const { handleLogOut, billing, usage } = useAuth()
  const isMobile = useMobileBreakpoints()

  const handleLogout = async (): Promise<void> => {
    await handleLogOut({ buttonInitiated: true })
    navigate(ROUTES_PATH.SIGNIN)
  }

  const handleListItemClick = (href: string): void => {
    if (href === EFFICIENCY_LINK) {
      window.open(href, '_blank')
    } else {
      navigate(href)
      setOpenSidebar(false)
    }
  }

  return (
    <>
      {isMobile
        ? <SwipeableDrawer PaperProps={{ sx: { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 } }} anchor="right" open={openSidebar} onClose={() => { setOpenSidebar(false) }} onOpen={() => { setOpenSidebar(true) }}>
          <Box p={3} width="70vw" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography color="#0f1111" fontWeight={600} fontSize={24} >More Options</Typography>
                <IconButton onClick={() => { setOpenSidebar(false) }}><CloseIcon color='primary' /></IconButton>
              </Box>
              {SIDEBAR_ITEMS.map((item, index) => (
                <ListItem key={index} sx={{ my: 1.5 }} disablePadding>
                  <ListItemButton disableGutters onClick={() => { handleListItemClick(item.href) }}>
                    <item.icon sx={{ color: item.href === location.pathname ? '#3532F4' : '#B9CDD9', mr: 2 }} />
                    <Typography fontSize={16} color='#010B0E' >{item.title}</Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <Box py={3} borderTop="solid 1px #E5E5F6" display="flex" justifyContent="space-between" alignItems="center">
              <ListItemButton disableGutters onClick={handleLogout}>
                <LogoutIcon sx={{ color: '#B9CDD9', mr: 2 }} />
                <Typography fontSize={16} color='#010B0E' >Log out</Typography>
              </ListItemButton>
              <Box component="img" src={Logo} alt="background" />
            </Box>
          </Box>
        </SwipeableDrawer>
        : <Wrapper>
          <SidebarInner>
            <Box display="block" textAlign="center" my={2}>
              <img src={LogoWhite} alt="MVU Logo" />
            </Box>
            <Box
              my={5}
              mx={2}
              display="flex"
              alignItems="center"
              flexDirection="column">
              <Box bgcolor='#4E53C4' width="93%" display="flex" p={1} alignItems="center" justifyContent="center">
                <Typography fontSize="12px" color="#ffffff">
                  SERVICE ADDRESS:
                </Typography>
              </Box>
              <ServiceAddress />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="calc(100vh - 300px)"
              style={{ overflow: 'scroll' }}
            >
              <SideNav>
                {SIDEBAR_ITEMS.map((link) => (
                  <li key={link.title}>
                    <NavItem
                      disabled={location.pathname === '/' && (billing === null || usage === null)}
                      to={link.href}
                      {...(link.href === EFFICIENCY_LINK ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                      isactive={link.href === location.pathname ? 'true' : 'false'}
                    >
                      <link.icon
                        sx={{
                          color:
                            link.href === location.pathname ? '#1513C3' : '#8387D6'
                        }}
                      />
                      <span>{link.title}</span>
                    </NavItem>
                  </li>
                ))}
              </SideNav>
              <SideFooter>
                <li>
                  <Link onClick={handleLogout} to={ROUTES_PATH.SIGNIN}>
                    Log Out
                  </Link>
                </li>
              </SideFooter>
            </Box>
          </SidebarInner>
        </Wrapper >
      }
    </>
  )
}

const Wrapper = styled(Box)({
  backgroundColor: '#1513C3',
  height: 'auto',
  left: '0',
  top: '0',
  paddingBottom: '100%',
  position: 'absolute',
  width: '240px',
  zIndex: '999',
  transition: 'all 0.25s'

})
const SidebarInner = styled(Box)`
  padding-top: 56px;
  overflow: hidden;
    height: auto;
`

const SideFooter = styled('ul')({
  paddingLeft: '48px',
  listStyleType: 'none',

  li: {
    marginBottom: '27px',

    a: {
      color: '#fff',
      cursor: 'pointer',
      display: 'block',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '17px',
      padding: '0',
      margin: '0',
      textDecoration: 'none'
    },

    '&:last-child': {
      marginBottom: '0'
    }
  }
})

const SideNav = styled('ul')({
  margin: '39px 0 0 20px',
  padding: 0,

  li: {
    display: 'block',
    marginBottom: '9px'
  }
})


const NavItem = styled(Link) <{ isactive: string, disabled?: boolean }>`
  color: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 12px 39px 11px 18px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  
  ${({ disabled }) => css`
    ${disabled === true &&
    css`
    pointer-events: none;
    opacity: 0.5;
    `}`}

  ${(props) => css`
    ${props.isactive === 'true' &&
    css`
      background-color: #f3f6ff !important;
      color: #010b0e;

      &:before {
        content: url("data:image/svg+xml,%3Csvg%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M40%2040V0C40%2022.0914%2022.0914%2040%200%2040H40Z%22%20fill%3D%22%23F5F7FF%22%2F%3E%0A%3C%2Fsvg%3E");
        position: absolute;
        right: 0;
        top: -40px;
        width: 40px;
        height: 40px;
      }

      &:after {
        content: url("data:image/svg+xml,%3Csvg%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M40%200V40C40%2017.9086%2022.0914%200%200%200H40Z%22%20fill%3D%22%23F5F7FF%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 40px;
        height: 40px;
      }
    `}
  `}

  span {
    font-size: 14px;
    line-height: 17px;
    padding: 0 0 0 8px;
    position: relative;
  }

  img: {
    position: absolute;
    height: 20px;
    width: 20px;
  }
`
